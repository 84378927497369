<template>
    <div class="flex aligm-item-center justify-content-center vh100">
      <div class="flex flex-column justify-content-center content-perfil">
            <div class="flex flex-column aligm-item-center justify-content-center cover-page">
                <span class="name">{{ usuario.apellido }} {{ usuario.nombre }}</span>
                <img class="perfil" :src="image" alt="Photo Perfil">
            </div>
            <div class="flex flex-row aligm-item-center justify-content-space-around icon-content">
                <a>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="rgb(252, 180, 24)" class="bi bi-globe-americas" viewBox="0 0 16 16">
                        <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0M2.04 4.326c.325 1.329 2.532 2.54 3.717 3.19.48.263.793.434.743.484-.08.08-.162.158-.242.234-.416.396-.787.749-.758 1.266.035.634.618.824 1.214 1.017.577.188 1.168.38 1.286.983.082.417-.075.988-.22 1.52-.215.782-.406 1.48.22 1.48 1.5-.5 3.798-3.186 4-5 .138-1.243-2-2-3.5-2.5-.478-.16-.755.081-.99.284-.172.15-.322.279-.51.216-.445-.148-2.5-2-1.5-2.5.78-.39.952-.171 1.227.182.078.099.163.208.273.318.609.304.662-.132.723-.633.039-.322.081-.671.277-.867.434-.434 1.265-.791 2.028-1.12.712-.306 1.365-.587 1.579-.88A7 7 0 1 1 2.04 4.327Z"/>
                    </svg>
                </a>
                <a>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="rgb(252, 180, 24)" class="bi bi-envelope-fill" viewBox="0 0 16 16">
                        <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"/>
                    </svg>
                </a>
                <a>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="rgb(252, 180, 24)" class="bi bi-box2-heart" viewBox="0 0 16 16">
                        <path d="M8 7.982C9.664 6.309 13.825 9.236 8 13 2.175 9.236 6.336 6.31 8 7.982"/>
                        <path d="M3.75 0a1 1 0 0 0-.8.4L.1 4.2a.5.5 0 0 0-.1.3V15a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V4.5a.5.5 0 0 0-.1-.3L13.05.4a1 1 0 0 0-.8-.4h-8.5Zm0 1H7.5v3h-6zM8.5 4V1h3.75l2.25 3zM15 5v10H1V5z"/>
                    </svg>
                </a>
                <a>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="rgb(252, 180, 24)" class="bi bi-grid-fill" viewBox="0 0 16 16">
                        <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5zm8 0A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5zm-8 8A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5zm8 0A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5z"/>
                    </svg>
                </a>
            </div>
            <div class="flex flex-column text-profile">
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-at-fill" viewBox="0 0 16 16">
                        <path d="M2 2A2 2 0 0 0 .05 3.555L8 8.414l7.95-4.859A2 2 0 0 0 14 2zm-2 9.8V4.698l5.803 3.546L0 11.801Zm6.761-2.97-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 9.671V4.697l-5.803 3.546.338.208A4.482 4.482 0 0 1 12.5 8c1.414 0 2.675.652 3.5 1.671"/>
                        <path d="M15.834 12.244c0 1.168-.577 2.025-1.587 2.025-.503 0-1.002-.228-1.12-.648h-.043c-.118.416-.543.643-1.015.643-.77 0-1.259-.542-1.259-1.434v-.529c0-.844.481-1.4 1.26-1.4.585 0 .87.333.953.63h.03v-.568h.905v2.19c0 .272.18.42.411.42.315 0 .639-.415.639-1.39v-.118c0-1.277-.95-2.326-2.484-2.326h-.04c-1.582 0-2.64 1.067-2.64 2.724v.157c0 1.867 1.237 2.654 2.57 2.654h.045c.507 0 .935-.07 1.18-.18v.731c-.219.1-.643.175-1.237.175h-.044C10.438 16 9 14.82 9 12.646v-.214C9 10.36 10.421 9 12.485 9h.035c2.12 0 3.314 1.43 3.314 3.034zm-4.04.21v.227c0 .586.227.8.581.8.31 0 .564-.17.564-.743v-.367c0-.516-.275-.708-.572-.708-.346 0-.573.245-.573.791Z"/>
                    </svg>
                    {{ usuario.email}}
                </span>
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar-event" viewBox="0 0 16 16">
                        <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5z"/>
                        <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
                    </svg>
                    {{ usuario.fecha_nacimiento }}
                </span>
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-vcard" viewBox="0 0 16 16">
                        <path d="M5 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4m4-2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5M9 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4A.5.5 0 0 1 9 8m1 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5"/>
                        <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H8.96c.026-.163.04-.33.04-.5C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1.006 1.006 0 0 1 1 12z"/>
                    </svg>
                    {{ usuario.dni }}
                </span>
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-badge" viewBox="0 0 16 16">
                        <path d="M6.5 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1zM11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
                        <path d="M4.5 0A2.5 2.5 0 0 0 2 2.5V14a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2.5A2.5 2.5 0 0 0 11.5 0zM3 2.5A1.5 1.5 0 0 1 4.5 1h7A1.5 1.5 0 0 1 13 2.5v10.795a4.2 4.2 0 0 0-.776-.492C11.392 12.387 10.063 12 8 12s-3.392.387-4.224.803a4.2 4.2 0 0 0-.776.492z"/>
                    </svg>
                    {{ usuario.cuil_t }}
                </span>
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-arms-up" viewBox="0 0 16 16">
                        <path d="M8 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3"/>
                        <path d="m5.93 6.704-.846 8.451a.768.768 0 0 0 1.523.203l.81-4.865a.59.59 0 0 1 1.165 0l.81 4.865a.768.768 0 0 0 1.523-.203l-.845-8.451A1.492 1.492 0 0 1 10.5 5.5L13 2.284a.796.796 0 0 0-1.239-.998L9.634 3.84a.72.72 0 0 1-.33.235c-.23.074-.665.176-1.304.176-.64 0-1.074-.102-1.305-.176a.72.72 0 0 1-.329-.235L4.239 1.286a.796.796 0 0 0-1.24.998l2.5 3.216c.317.316.475.758.43 1.204Z"/>
                    </svg>
                    {{ usuario.cargo }}
                </span>
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-receipt" viewBox="0 0 16 16">
                        <path d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27m.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0z"/>
                        <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5m8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5"/>
                    </svg>
                    {{ usuario.tipo_cargo }}
                </span>
                <span >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-archive" viewBox="0 0 16 16">
                        <path d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 12.5V5a1 1 0 0 1-1-1zm2 3v7.5A1.5 1.5 0 0 0 3.5 14h9a1.5 1.5 0 0 0 1.5-1.5V5zm13-3H1v2h14zM5 7.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5"/>
                    </svg>
                    {{ usuario.legajo }}
                </span>
                <!-- Botón para abrir el formulario -->
                <button v-if="this.isGuestMode!=true" class="btnEdit" @click="mostrarFormulario">
                    <span>
                        Editar Datos
                    </span>
                </button>
                <button v-if="mostrarForm && this.isGuestMode!==true" class="btnClose" @click="ocultarFormulario">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="rgb(252, 180, 24)" class="bi bi-x-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                    </svg>
                </button>
            </div>
        </div>
    </div>
    <!-- Formulario de edición -->
    <div v-if="mostrarForm && this.isGuestMode!==true" class="flex  aligm-item-center justify-content-center content-form vh100">
        <form id="form" @submit.prevent="editUser" class="user-form absolute vh100">
            <div class="user-form-image">
                <img class="circle" src="@/assets/img/MOLINO.png" width="64" alt="Imagen" />
                <img class="circle" v-if="localimage" :src="localimage" width="64" alt="Imagen" />
            </div>
            <div class="user-form-item">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-raised-hand" viewBox="0 0 16 16">
                <path d="M6 6.207v9.043a.75.75 0 0 0 1.5 0V10.5a.5.5 0 0 1 1 0v4.75a.75.75 0 0 0 1.5 0v-8.5a.25.25 0 1 1 .5 0v2.5a.75.75 0 0 0 1.5 0V6.5a3 3 0 0 0-3-3H6.236a.998.998 0 0 1-.447-.106l-.33-.165A.83.83 0 0 1 5 2.488V.75a.75.75 0 0 0-1.5 0v2.083c0 .715.404 1.37 1.044 1.689L5.5 5c.32.32.5.754.5 1.207"/>
                <path d="M8 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3"/>
                </svg>
                <input v-model="localEmail" type="email" placeholder="Email" readonly>
            </div>
            <div class="user-form-item">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-vcard" viewBox="0 0 16 16">
                <path d="M5 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4m4-2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5M9 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4A.5.5 0 0 1 9 8m1 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5"/>
                <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H8.96c.026-.163.04-.33.04-.5C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1.006 1.006 0 0 1 1 12z"/>
                </svg>
                <input v-model="nombre" type="text" placeholder="Nombre Completo" required>
            </div>
            <div class="user-form-item">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-vcard" viewBox="0 0 16 16">
                <path d="M5 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4m4-2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5M9 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4A.5.5 0 0 1 9 8m1 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5"/>
                <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H8.96c.026-.163.04-.33.04-.5C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1.006 1.006 0 0 1 1 12z"/>
                </svg>
                <input v-model="apellido" type="text" placeholder="Apellido" required>
            </div>
            <div class="user-form-item">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-raised-hand" viewBox="0 0 16 16">
                <path d="M6 6.207v9.043a.75.75 0 0 0 1.5 0V10.5a.5.5 0 0 1 1 0v4.75a.75.75 0 0 0 1.5 0v-8.5a.25.25 0 1 1 .5 0v2.5a.75.75 0 0 0 1.5 0V6.5a3 3 0 0 0-3-3H6.236a.998.998 0 0 1-.447-.106l-.33-.165A.83.83 0 0 1 5 2.488V.75a.75.75 0 0 0-1.5 0v2.083c0 .715.404 1.37 1.044 1.689L5.5 5c.32.32.5.754.5 1.207"/>
                <path d="M8 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3"/>
                </svg>
                <input v-model="fecha_nacimiento" type="date" placeholder="date" required>
            </div>
            <div class="user-form-item">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-vcard" viewBox="0 0 16 16">
                <path d="M5 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4m4-2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5M9 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4A.5.5 0 0 1 9 8m1 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5"/>
                <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H8.96c.026-.163.04-.33.04-.5C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1.006 1.006 0 0 1 1 12z"/>
                </svg>
                <input v-model="dni" type="text" placeholder="DNI" required>
            </div>
            <div class="user-form-item">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-lines-fill" viewBox="0 0 16 16">
                <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5m.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1z"/>
                </svg>
                <input v-model="cuil_t" type="text" placeholder="CUIL/T 00-00000000-0" @blur="formatCuil" required>
            </div>
            <div class="user-form-item">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-lines-fill" viewBox="0 0 16 16">
                    <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5m.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1z"/>
                </svg>
                <select v-model="cargo" @change="updateRole">
                <option value="DOCENTE">Docente</option>
                <option value="NO DOCENTE">No Docente</option>
                <option value="SUPERIOR">Administración</option>
                <option value="ALUMNO">Alumno</option>
                <option value="COMERCIO">Comercio</option>
                <option value="OTROS">Otros</option>
                </select>
            </div>
            <div v-if="showCargoTipoCargo" class="user-form-item">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-video" viewBox="0 0 16 16">
                <path d="M8 9.05a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5"/>
                <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zm10.798 11c-.453-1.27-1.76-3-4.798-3-3.037 0-4.345 1.73-4.798 3H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1z"/>
                </svg>
                <select v-model="tipo_cargo">
                <option value="PERMANENTE">Permanente</option>
                <option value="TRANSITORIO">Transitorio</option>
                </select>
            </div>
            <div v-if="showCargoTipoCargo" class="user-form-item">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-vcard" viewBox="0 0 16 16">
                <path d="M5 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4m4-2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5M9 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4A.5.5 0 0 1 9 8m1 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5"/>
                <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H8.96c.026-.163.04-.33.04-.5C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1.006 1.006 0 0 1 1 12z"/>
                </svg>
                <input v-model="legajo" type="text" placeholder="Legajo" required>
            </div>

            <button type="submit">Guardar cambios</button>
        </form>
    </div>
</template>
<script>
export default {
    inject: ['showNotification', 'notificationDetails'],
    props: {
        email: String,
        profile: Object,
        image: String,
        logout: Function,
        isGuestMode: Boolean,
        parentMethods: {
            type: Object,
            required: true,
        },
    },
    data() {
        const defaultProfile = {
            email: 'usuariotest@unraf.edu.ar',
            nombre: 'Usuario',
            apellido: 'Usuario',
            fecha_nacimiento: '01/01/1900',
            dni: '00000000',
            cuil_t: '00-00000000-0',
            cargo: 'Invitado',
            tipo_cargo: 'Invitado',
            legajo: '000000'
        };
        const profile = this.profile || defaultProfile;
        return {
            usuario: profile,
            mostrarForm: false,
            localEmail: profile.email,
            localimage: this.image || '',
            nombre: profile.nombre,
            apellido: profile.apellido,
            fecha_nacimiento: profile.fecha_nacimiento,
            dni: profile.dni,
            cuil_t: profile.cuil_t,
            cargo: profile.cargo,
            tipo_cargo: profile.tipo_cargo,
            legajo: profile.legajo,
            showCargoTipoCargo: false,
            csrfToken: ''
        };
    },
    created(){
        this.fetchCsrfToken();
        this.updateRole();
    },
    mounted() {
        // Realiza una llamada a la API para obtener los datos del usuario
        // Por ejemplo, usando axios u otra biblioteca para hacer solicitudes HTTP
        // Aquí, se asume que la API devuelve los datos del usuario en formato JSON
        // Proporciona las variables globales utilizando la función provide
    },
    methods: {
        recargarDatos() {
        // Recargar los datos asignando nuevamente los valores iniciales
        this.usuario = this.$root.userData;
        this.mostrarForm = false;
        this.localEmail = this.$root.userData.email || '';
        this.localimage = this.image || '';
        this.nombre = this.$root.userData.nombre;
        this.apellido = this.$root.userData.apellido;
        this.fecha_nacimiento = this.$root.userData.fecha_nacimiento;
        this.dni = this.$root.userData.dni;
        this.cuil_t = this.$root.userData.cuil_t;
        this.cargo = this.$root.userData.cargo;
        this.tipo_cargo = this.$root.userData.tipo_cargo;
        this.legajo = this.$root.userData.legajo;
        this.showCargoTipoCargo = false;
        this.csrfToken = '';
        this.showNotification = false;
        this.notificationDetails = { title: 'success', body: '' };
        },
        async fetchCsrfToken() {
            try {
                const response = await fetch('/api/get-csrf-token/');
                const data = await response.json();
                this.csrfToken = data.csrf_token;
            } catch (error) {
                console.error('Error al obtener el token CSRF:', error);
            }
        },
        formatCuil() {
            // Verificar si el CUIL ya está formateado
            const isFormatted = /^\d{2}-\d{8}-\d{1}$/.test(this.cuil_t);

            if (!isFormatted) {
                // Si no está formateado, realizar el formateo
                this.cuil_t = this.cuil_t.replace(/\D/g, ''); // Eliminar caracteres no numéricos

                // Agregar ceros si faltan números
                while (this.cuil_t.length < 11) {
                this.cuil_t = '0' + this.cuil_t;
                }

                this.cuil_t = this.cuil_t.replace(/^(\d{2})(\d{8})(\d{1})$/, '$1-$2-$3');
            }
        },
        updateRole() {
            // Mostrar Cargo y Tipo de Cargo solo si el rol es Docente, No Docente o Administración
            this.showCargoTipoCargo = ['DOCENTE', 'NO DOCENTE', 'SUPERIOR'].includes(this.cargo);
        },
        mostrarFormulario() {
            this.mostrarForm = true;
        },
        ocultarFormulario() {
            this.mostrarForm = false;
        },
        async editUser() {

            // Asegúrate de que todos los campos necesarios tengan valores
            if (!this.email || !this.dni || !this.cuil_t || !this.cargo || !this.nombre || !this.apellido || !this.fecha_nacimiento) {
            console.error('Todos los campos deben tener valores.');
            return;
            }
            // Aquí debes enviar los datos al servidor
            const dataUser = {
            email: this.email,
            fecha_nacimiento: this.fecha_nacimiento,
            nombre: this.nombre,
            apellido: this.apellido,
            dni: this.dni,
            cuil_t: this.cuil_t,
            cargo: this.cargo,
            tipo_cargo: this.tipo_cargo || null,
            legajo: this.legajo,
            foto_base64: null,
            };
            // Lógica para enviar los datos al servidor, por ejemplo, usando fetch
            fetch('/api/actualizar_usuario/', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': this.csrfToken,
            },
            body: JSON.stringify(dataUser),
            })
            .then((response) => response.json())
            .then(async (data) => {
                await this.parentMethods.actualizarUsuario(this.email);
                this.userData = this.$root.userData;
                // Restablecer los valores del formulario
                this.resetForm();
                this.recargarDatos();
                this.$root.showNotification = true;
                this.$root.notificationDetails = { title: 'success', body: data.mensaje };
            })
            .catch((error) => {
            console.error('Error al crear el usuario:', error);
            this.logout();
            });
        },
        resetForm() {
            // Restablecer los valores del formulario a los iniciales o vacíos
            this.mostrarForm = false;
        }
    }
};
</script>

<style scoped>
/* Estilos específicos del componente */
@import '@/assets/css/index.css';
@import '@/assets/css/userForm.css';
.btnEdit{
    width:100%;
    border: none;
    outline: none;
    text-decoration: none;
    background-color: transparent;
}
.btnEdit span{
    
    background-color: #1CA1A7;
    color: #ffffff;
    padding: 12px 20px;
    font-weight: 500;
    font-size: 1.2rem;
    border: none;
    border-radius: 20px; /* Borde redondeado */
    cursor: pointer;
    font-family: 'Museo', sans-serif;
}
.content-form{
    position: absolute;
    width: 100%;
    height: 100%;
    background: #7a7a7a8e;
    top:0;
    left: 0;
    
}
.content-perfil{
    background: #FAF7F3;
    width: 100%;
    height: 100%;
    margin: 2% auto;
    border-radius: 10px;
    box-shadow: 0px 0px 200px rgba(0, 0, 0, 0.4);
    overflow: hidden;
}
.cover-page{
    background: linear-gradient(to left, #70e1f5, #ffd194);
    background: url("https://static.pexels.com/photos/93013/pexels-photo-93013-large.jpeg");
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 295px;
    opacity: 0.8;
    position: relative;

}
.name{
    font-size: 1.8em;
    font-family: "Ubuntu", sans-serif;
    color: #fff;
    text-align: center;
    position: absolute;
    width: 100%;
    top: 15%;
    transform: translateX(-50%, -50%);
    margin: 1%;
}
.perfil{
    width: 120px;
    height: 120px;
    border-radius: 50%;
    box-shadow: 0px 0px 16px #000000;
    position: absolute;
    bottom: 15%;
    transform: translateX(-50%, -50%);
    z-index: 1;
}
.icon-content{
    margin: 10px;
}
.text-profile{
    font-size: 1.2em;
    font-family: "Ubuntu", sans-serif;
    color: #767e8a;
    margin: 10px;
}
</style>