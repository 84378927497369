<!-- QRScannerComponent.vue -->
<template>
  <div class="qr-content">  
    <div class="scan-code">
      <span class="scan-code__angle scan-code__angle--top"></span>
      <span class="scan-code__angle scan-code__angle--bottom"></span>
    </div>
    <div class="qr-scan">
      <qrcode-stream :paused="paused" @detect="onDetect" @camera-on="onCameraOn" @camera-off="onCameraOff" @error="onError">
        <div v-show="showScanConfirmation" class="scan-confirmation">
          <img src="@/assets/img/MOLINO.png" alt="Checkmark" />
        </div>
        <div v-show="showScanError" class="scan-confirmation">
          <img src="@/assets/img/error.webp" alt="Errormark" />
        </div>
        <div v-show="showScanCheck" class="scan-confirmation">
          <img src="@/assets/img/check.png" alt="Checkmark" />
        </div>
      </qrcode-stream>
    </div>
    <p class="decode-result">
    <b>{{ result }}</b>
    </p>
  </div>
</template>

<script>

import { QrcodeStream } from 'vue-qrcode-reader'
import axios from 'axios';
export default {
  components: { QrcodeStream },
  props: {
      profile: Object,
    },
  data() {
    return {
      paused: false,
      result: "",
      qr_string: "",
      showScanConfirmation: false,
      showScanError: false,
      showScanCheck: false,
    }
  },

  methods: {
    onCameraOn() {
      this.showScanConfirmation = false
    },

    onCameraOff() {
      this.showScanConfirmation = true
    },
    scheduleReset() {
      setTimeout(() => {
        this.showScanError = false;
        this.showScanCheck = false;
        this.paused = false;
        this.result = "";
      }, 5000); // Cambia el tiempo (5000 ms = 5 segundos) según sea necesario
    },
    onError: console.error,
    async obtenerDireccionIP() {
      try {
      const response = await axios.get('/api/get_ip/', {
          // Configuración adicional si es necesario
      });
        return response.data.direccion_ip;
      } catch (error) {
        console.error('Error al obtener la dirección IP:', error);
        return null;
      }
    },
    async onDetect(detectedCodes) {
      this.qr_string = JSON.stringify(
        detectedCodes.map(code => code.rawValue)
      );
      console.log(this.result);

      // Realizar la llamada a la API de Django
      try {
        const ipAddress = await this.obtenerDireccionIP();
        if (ipAddress) {
          const response = await axios.post('/api/validar_usuario/', {
            usuario_codificado: this.qr_string,
            client_ip: ipAddress,
            client_email: this.profile.email
          });
          if(response.data.status === 'CONSULT SUCCESS'){
            this.result = response.data.response
            this.showScanCheck= true;
          }else if(response.data.status === 'CONSULT ERROR'){
            this.result = response.data.error
            this.showScanError= true
          }else{
            this.result = 'NO SE PUDO COMPROBAR LOS DATOS'
            this.showScanError= true
          }
        }else {
          this.result = 'NO SE PUDO COMPROBAR LOS DATOS'
          this.showScanError= true
        }
      } catch (error) {
        this.result = 'NO SE PUDO COMPROBAR LOS DATOS'
        this.showScanError= true
      }

      this.paused = true;
      this.scheduleReset();
    },

    timeout(ms) {
      return new Promise((resolve) => {
        window.setTimeout(resolve, ms)
      })
    },

  }
}
</script>

<style scoped>
.qr-content{
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;

}
.qr-scan{
  width: 400px;
  height: 400px;
}
.decode-result{
  position: fixed;
  color: blueviolet;
  text-align: center;
  font-size: 1rem;
}
.scan-confirmation {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(116, 116, 116, 0.2);

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  z-index: 2;
}

.scan-code {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 200px;
  height: 200px;
  z-index: 1;
}
.scan-code__angle:before,
.scan-code__angle:after {
  content: "";
  position: absolute;
  width: 60px;
  height: 60px;
  border-style: solid;
  border-color: rgba(255,255,255,0.6);
  border-width: 0;
}

.scan-code__angle:before {
  border-left-width: 6px;
  left: 0;
}

.scan-code__angle:after {
  border-right-width: 6px;
  right: 0;
}

.scan-code__angle--top:before,
.scan-code__angle--top:after {
  top: 0;
  border-top-width: 6px;
}

.scan-code__angle--bottom:before,
.scan-code__angle--bottom:after {
  bottom: 0;
  border-bottom-width: 6px;
}

.scan-code__angle--top:before {
  border-top-left-radius: 4px;
}

.scan-code__angle--top:after {
  border-top-right-radius: 4px;
}

.scan-code__angle--bottom:before {
  border-bottom-left-radius: 4px;
}

.scan-code__angle--bottom:after {
  border-bottom-right-radius: 4px;
}
</style>