import { createApp, ref } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import GoogleLogin from 'vue3-google-login'
import * as VueQrcodeReader from 'vue-qrcode-reader'
import QrcodeVue from 'qrcode.vue'

// Definir la variable clientId
export const clientId = '245140926466-53gv1njblk3mvtoiclgp43codq21d1ac.apps.googleusercontent.com'
const app = createApp(App)

// Registrar el Service Worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/unraf/firebase-messaging-sw.js')
    .then((registration) => {
      console.log('Service Worker registrado con éxito:', registration);
    })
    .catch((error) => {
      console.error('Error al registrar el Service Worker:', error);
    });
}

// Configuración de la cookie SIDCC
document.cookie = 'SIDCC=value; SameSite=None; Secure';

// Configuración de la cookie __Secure-1PSIDCC
document.cookie = '__Secure-1PSIDCC=value; SameSite=None; Secure';

// Usar Vuex para gestionar el estado de autenticación a nivel global
const isAuthenticated = ref(false)
const showNotification = ref(false)
const notificationDetails = ref({ title: '', body: '' })

// Usar express y configurar middlewares
app.use(store)

// Inyectar el estado de autenticación a nivel global
app.provide('isAuthenticated', isAuthenticated)
app.provide('showNotification', showNotification)
app.provide('notificationDetails', notificationDetails)

app.use(GoogleLogin, {
  clientId: clientId,
});

app.use(router);
app.use(QrcodeVue);
app.use(VueQrcodeReader);
app.mount('#app');