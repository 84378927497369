<template>
  <div>
    <form id="form" @submit.prevent="createUser" class="user-form">
      <div class="user-form-image">
        <img class="circle" src="@/assets/img/MOLINO.png" width="64" alt="Imagen" />
        <button id="logout-button" @click="changeEmail" v-if="localimage" ><img class="circle" :src="localimage" width="64" alt="Imagen" /></button>
      </div>
      <div class="user-form-item">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-raised-hand" viewBox="0 0 16 16">
          <path d="M6 6.207v9.043a.75.75 0 0 0 1.5 0V10.5a.5.5 0 0 1 1 0v4.75a.75.75 0 0 0 1.5 0v-8.5a.25.25 0 1 1 .5 0v2.5a.75.75 0 0 0 1.5 0V6.5a3 3 0 0 0-3-3H6.236a.998.998 0 0 1-.447-.106l-.33-.165A.83.83 0 0 1 5 2.488V.75a.75.75 0 0 0-1.5 0v2.083c0 .715.404 1.37 1.044 1.689L5.5 5c.32.32.5.754.5 1.207"/>
          <path d="M8 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3"/>
        </svg>
        <input v-model="localEmail" type="email" placeholder="Email" readonly>
      </div>
      <div class="user-form-item">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-lines-fill" viewBox="0 0 16 16">
            <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5m.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1z"/>
          </svg>
        <select v-model="cargo" @change="updateRole" required>
          <option value="DOCENTE">Docente</option>
          <option value="NO DOCENTE">No Docente</option>
          <option value="SUPERIOR">Administración</option>
          <option value="CONTRATADO">Agente</option>
          <option value="ALUMNO">Estudiante</option>
          <option value="COMERCIO">Comercio</option>
          <option value="OTROS">Otros</option>
        </select>
      </div>
      <div v-if="showCargoTipoCargo" class="user-form-item">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-video" viewBox="0 0 16 16">
          <path d="M8 9.05a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5"/>
          <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zm10.798 11c-.453-1.27-1.76-3-4.798-3-3.037 0-4.345 1.73-4.798 3H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1z"/>
        </svg>
        <select v-model="tipo_cargo">
          <option value="PERMANENTE">Permanente</option>
          <option value="TRANSITORIO">Transitorio</option>
        </select>
      </div>
      <div v-if="showCargoTipoCargo" class="user-form-item">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-vcard" viewBox="0 0 16 16">
          <path d="M5 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4m4-2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5M9 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4A.5.5 0 0 1 9 8m1 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5"/>
          <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H8.96c.026-.163.04-.33.04-.5C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1.006 1.006 0 0 1 1 12z"/>
        </svg>
        <input v-model="legajo" type="text" placeholder="Legajo" required>
      </div>
      <div class="user-form-item">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-vcard" viewBox="0 0 16 16">
          <path d="M5 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4m4-2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5M9 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4A.5.5 0 0 1 9 8m1 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5"/>
          <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H8.96c.026-.163.04-.33.04-.5C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1.006 1.006 0 0 1 1 12z"/>
        </svg>
        <input v-model="nombre" type="text" placeholder="Nombres" required>
      </div>
      <div class="user-form-item">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-vcard" viewBox="0 0 16 16">
          <path d="M5 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4m4-2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5M9 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4A.5.5 0 0 1 9 8m1 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5"/>
          <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H8.96c.026-.163.04-.33.04-.5C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1.006 1.006 0 0 1 1 12z"/>
        </svg>
        <input v-model="apellido" type="text" placeholder="Apellido" required>
      </div>
      <div class="user-form-item">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-raised-hand" viewBox="0 0 16 16">
          <path d="M6 6.207v9.043a.75.75 0 0 0 1.5 0V10.5a.5.5 0 0 1 1 0v4.75a.75.75 0 0 0 1.5 0v-8.5a.25.25 0 1 1 .5 0v2.5a.75.75 0 0 0 1.5 0V6.5a3 3 0 0 0-3-3H6.236a.998.998 0 0 1-.447-.106l-.33-.165A.83.83 0 0 1 5 2.488V.75a.75.75 0 0 0-1.5 0v2.083c0 .715.404 1.37 1.044 1.689L5.5 5c.32.32.5.754.5 1.207"/>
          <path d="M8 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3"/>
        </svg>
        <input v-model="fecha_nacimiento" type="date" placeholder="date" required>
      </div>
      <div class="user-form-item">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-vcard" viewBox="0 0 16 16">
          <path d="M5 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4m4-2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5M9 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4A.5.5 0 0 1 9 8m1 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5"/>
          <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H8.96c.026-.163.04-.33.04-.5C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1.006 1.006 0 0 1 1 12z"/>
        </svg>
        <input v-model="dni" type="text" placeholder="DNI/Pasaporte" required>
      </div>
      <div v-if="showCargoTipoCargo" class="user-form-item">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-lines-fill" viewBox="0 0 16 16">
          <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5m.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1z"/>
        </svg>
        <input v-model="cuil_t" type="text" placeholder="CUIL/T 00-00000000-0" @blur="formatCuil" required>
      </div>
      <button type="submit">Crear Usuario</button>
    </form>
  </div>
</template>

<script>
// eslint-disable-next-line
import { ref, watch } from 'vue';
import axios from 'axios'; // Add this line
export default {
  inject: ['showNotification', 'notificationDetails'],
  props: {
    // Definir la prop 'email' recibida desde app.vue
    email: String,
    image: String,
    logout: Function,
  },
  data() {
    return {
      localEmail: this.email || '',
      localimage: this.image || '',
      nombre:'',
      apellido:'',
      fecha_nacimiento:'',
      dni: '',
      cuil_t: null,
      cargo: '',
      tipo_cargo: null,
      legajo: null,
      showCargoTipoCargo: false,
      csrfToken: '',
    };
  },
  created(){
    this.fetchCsrfToken();
  },
  methods: {
    async fetchCsrfToken() {
      try {
        const response = await fetch('/api/get-csrf-token/');
        const data = await response.json();
        this.csrfToken = data.csrf_token;
      } catch (error) {
        console.error('Error al obtener el token CSRF:', error);
      }
    },
    async obtenerDireccionIP() {
        try {
        const response = await axios.get('/api/get_ip/', {
            // Configuración adicional si es necesario
        });
          return response.data.direccion_ip;
        } catch (error) {
          console.error('Error al obtener la dirección IP:', error);
          return null;
        }
      },
    changeEmail() {
      this.logout();
    },
    formatCuil() {
      // Verificar si el CUIL ya está formateado
      const isFormatted = /^\d{2}-\d{8}-\d{1}$/.test(this.cuil_t);
      if (!isFormatted) {
        // Si no está formateado, realizar el formateo
        this.cuil_t = this.cuil_t.replace(/\D/g, ''); // Eliminar caracteres no numéricos
        // Agregar ceros si faltan números
        while (this.cuil_t.length < 11) {
          this.cuil_t = '0' + this.cuil_t;
        }
        this.cuil_t = this.cuil_t.replace(/^(\d{2})(\d{8})(\d{1})$/, '$1-$2-$3');
      }
    },
    updateRole() {
      // Primero, reiniciamos el estado de `showCargoTipoCargo` para asegurarnos de que no contenga un estado anterior no deseado.
      this.showCargoTipoCargo = false;
      let email = this.localEmail;
      let dominio = "@unraf.edu.ar";
      // Verificar si el cargo está en la lista permitida
      if (['DOCENTE', 'NO DOCENTE', 'SUPERIOR'].includes(this.cargo)) {
        // Verificar si el email termina con el dominio especificado
        if (email.includes(dominio)) {
          this.showCargoTipoCargo = true;  // Mostrar opciones adicionales si el email es correcto
        } else {
          // Procedimientos en caso de email no válido
          this.logout(); // Llamar función de cierre de sesión
          // Mostrar notificación a través de una instancia raíz
          this.$root.showNotification = true;
          this.$root.notificationDetails = { title: 'error', body: 'El correo no pertenece a personal de la UNRaf' };
        }
      }
    },
    async createUser() {
      const ipAddress = await this.obtenerDireccionIP();
        // Asegúrate de que todos los campos necesarios tengan valores
      if (!this.email || !this.dni || !this.cargo || !this.nombre || !this.apellido || !this.fecha_nacimiento) {
        console.error('Todos los campos deben tener valores.');
        return;
      }
      // Aquí debes enviar los datos al servidor
      const userData = {
        email: this.email,
        client_ip: ipAddress,
        fecha_nacimiento: this.fecha_nacimiento,
        nombre: this.nombre,
        apellido: this.apellido,
        dni: this.dni,
        cuil_t: this.cuil_t,
        cargo: this.cargo,
        tipo_cargo: this.tipo_cargo,
        legajo: this.legajo,
        foto_base64: null,
      };
      // Lógica para enviar los datos al servidor, por ejemplo, usando fetch
      fetch('/api/crear_usuario/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': this.csrfToken,
        },
        body: JSON.stringify(userData),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.exito != true) {
            // Duplicate entry error
            this.logout();             
            this.$root.showNotification = true;
            this.$root.notificationDetails = { title: 'error', body: data.mensaje };
          }else{
            this.logout();
            this.$root.showNotification = true;
            this.$root.notificationDetails = { title: 'success', body: data.mensaje };
          }
        })
        .catch((error) => {
          console.error('Error al crear el usuario:', error);
          this.logout();
        });
    },
  },
  watch: {
    // Si el valor de 'email' cambia en la prop, actualizar localmente
    email(newValue) {
      this.localEmail = newValue;
    },
  },
};
</script>
<style scoped>
@import '@/assets/css/userForm.css';
@import '@/assets/css/home.css';
</style>