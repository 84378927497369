<template>
    <div class="content-carnet" ref="carnet">
      <div class="carnet">
        <div class="header">
          <div class="header-logo">
            <img src="@/assets/img/MOLINO.png" alt="Imagen"/>
          </div>
          <div class="header-title">
            <span>UNRaf</span>
          </div>
        </div>
        <div class="body">
          <div class="photo-carnet">
            <img src="@/assets/img/MOLINO.png" alt="Imagen"/>
          </div>
          <div class="profile-carnet">
            <span class="colo-blue">{{ nombre }} {{ apellido }}</span>
            <span class="colo-ligthblue"> {{ cargo }}</span>

          </div>
          
        </div>
        <div class="footer">
          <div class="qr-code">
            <div id="qr" class="profile-carnet">
              <qrcode-vue  :value="value" level="H" />
            </div>
          </div>
        </div>
      </div>
      <!--
      <div class="carnet">
        <div class="header">
          <div class="header-logo">
            <img src="@/assets/img/MOLINO.png" alt="Imagen"/>
          </div>
          <div class="header-title">
            <span>Universidad Nacional de Rafaela</span>
            <span>Argentina - Santa Fe - Rafaela</span>
          </div>
        </div>
        <div class="body">
          <div class="photo-carnet">
          </div>
          <div id="qr" class="profile-carnet">
            <qrcode-vue  :value="value" level="H" />
          </div>
        </div>
        <div class="footer">
          <div class="qr-code">
          </div>
        </div>
      </div>
      -->
      <button @click="descargarPDF">Descargar Carnet en PDF</button>
    </div>


</template>
  
  <script>
  import QrcodeVue from 'qrcode.vue'
  import html2pdf from 'html2pdf.js';
  import axios from 'axios';
  export default {
    props: {
      email: String,
      profile: Object,
    },
    data() {
      return {
        value: 'https://example.com',
        profile_email: this.email,
        apellido: '',
        nombre: '',
        dni: '',
        fecha_nacimiento: '',
        direccion: '',
        cargo: '',
        csrfToken: null,

      }
    },
    methods: {
      async fetchCsrfToken() {
        try {
          const response = await fetch('/api/get-csrf-token/');
          const data = await response.json();
          this.csrfToken = data.csrf_token;
        } catch (error) {
          console.error('Error al obtener el token CSRF:', error);
        }
      },
      descargarPDF() {
        const content = this.$refs.carnet;

        html2pdf(content, {
          margin: 10,
          filename: 'credencial.pdf',
          html2canvas: { scale: 1 },
          jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        });
      },
      async actualizarQR() {
      try {
          const response = await axios.post('/api/create_qr/', {
            email: this.email,
          });
          if (response.data.success) {
              // Actualizar el valor del código QR con la información del usuario
              this.value = response.data.usuario_codificado;
            } else {
              this.value = "";
              console.error("Email no valid");
            }
        } catch (error) {
          console.error('Error al llamar a la API de Django:', error);
        }
      },
      async obtenerDireccionIP() {
        try {
        const response = await axios.get('/api/get_ip/', {
            // Configuración adicional si es necesario
        });
          return response.data.direccion_ip;
        } catch (error) {
          console.error('Error al obtener la dirección IP:', error);
          return null;
        }
      },
      async actualizarDatosPersonal() {
      try {
        const ipAddress = await this.obtenerDireccionIP();
        if (ipAddress) {
                // Agrega el token CSRF al encabezado de la solicitud
          const headers = {
            'Content-Type': 'application/json',
            'X-CSRFToken': this.csrfToken,
          };
          // Datos a enviar en la solicitud
          
          const data = {
            email: this.profile_email,
            client_ip: ipAddress,
            dni: this.profile.dni,
            cuit: this.profile.cuil_t,
            nombre_cargo:  this.profile.cargo,
            tipo_cargo: this.profile.tipo_cargo,
            legajo: this.profile.legajo,
          };
          const response = await axios.post('/api/datos_personal/', data, { headers });
          if (response.data.success) {
              // Actualizar el valor del código QR con la información del usuario
              const datos= response.data.response[0]
              this.apellido= datos.apellido;
              this.nombre = datos.nombre;
              this.dni = datos.dni;
              this.fecha_nacimiento = datos.fecha_nacimiento
              this.cargo = this.profile.cargo
              this.direccion = datos.pais + " "+ datos.provincia + " "+ datos.localidad

            } else {
              this.apellido= "No se pueden comprobar los datos";
              this.nombre = "No se pueden comprobar los datos";
              this.dni = "No se pueden comprobar los datos";
              this.fecha_nacimiento = "No se pueden comprobar los datos"
              this.cargo = "No se pueden comprobar los datos"
              this.direccion = "No se pueden comprobar los datos"
              this.$root.showNotification = true;
              this.$root.notificationDetails = { title: 'error', body: response.data.message };
            }
          
        }else{
            this.apellido= "Error";
              this.nombre = "Error";
              this.dni = "Error";
              this.fecha_nacimiento = "Error"
              this.cargo = "Error"
              this.direccion = "Error"
        }
        } catch (error) {
          console.error('Error al llamar a la API de Django:', error);
        }
      },
      async actualizarDatosAlumno() {
      try {
        const ipAddress = await this.obtenerDireccionIP();
        if (ipAddress) {
          // Agrega el token CSRF al encabezado de la solicitud
          const headers = {
            'Content-Type': 'application/json',
            'X-CSRFToken': this.csrfToken,
          };
          // Datos a enviar en la solicitud
          
          const data = {
            email: this.profile_email,
            client_ip: ipAddress,
            dni: this.profile.dni,
            fecha: this.profile.fecha_nacimiento,
            nombre: this.profile.nombre,
            apellido: this.profile.apellido,
          };
          const response = await axios.post('/api/datos_alumno/', data, { headers });
          if (response.data.success) {
              // Actualizar el valor del código QR con la información del usuario
              const datos= response.data.response[0]
              this.apellido= datos.apellido;
              this.nombre = datos.nombre;
              this.dni = datos.dni;
              this.fecha_nacimiento = datos.fecha_nacimiento
              this.cargo = this.profile.cargo
              this.direccion = datos.pais + " "+ datos.provincia + " "+ datos.localidad

            } else {
              this.apellido= "No se pueden comprobar los datos";
              this.nombre = "No se pueden comprobar los datos";
              this.dni = "No se pueden comprobar los datos";
              this.fecha_nacimiento = "No se pueden comprobar los datos"
              this.cargo = "No se pueden comprobar los datos"
              this.direccion = "No se pueden comprobar los datos"
              this.value="";
              this.$root.showNotification = true;
              this.$root.notificationDetails = { title: 'error', body: response.data };
            }
          
        }else{
            this.apellido= "ERROR";
            this.nombre = "ERROR";
            this.dni = "ERROR";
            this.fecha_nacimiento = "ERROR"
            this.cargo = "ERROR"
            this.direccion = "ERROR"
            this.value="";
        }
        } catch (error) {
          this.apellido= "ERROR";
          this.nombre = "ERROR";
          this.dni = "ERROR";
          this.fecha_nacimiento = "ERROR"
          this.cargo = "ERROR"
          this.direccion = "ERROR"
          this.value="";
          this.$root.showNotification = true;
          this.$root.notificationDetails = { title: 'error', body:  error};
          console.error('Error al llamar a la API de Django:', error);
        }
      },
    },
    mounted() {
      this.fetchCsrfToken();
      this.actualizarQR();
      if (this.profile.cargo==='ALUMNO') {
        this.actualizarDatosAlumno();
      }else if (this.profile.cargo==='NO DOCENTE' || this.profile.cargo==='DOCENTE' || this.profile.cargo==='SUPERIOR'){
        this.actualizarDatosPersonal();
      }else{
        this.apellido= "ERROR";
        this.nombre = "ERROR";
        this.dni = "ERROR";
        this.fecha_nacimiento = "ERROR"
        this.cargo = "ERROR"
        this.direccion = "ERROR"
      }
    },
    components: {
      QrcodeVue,
    },
  }
</script>
<style>
.content-carnet{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: blueviolet;
}
.carnet{
  display: flex;
  flex-direction: column;
  position: relative;
  width: 8.9cm;
  height: 11.86cm;
  background-image: url("@/assets/img/fondo-credencial.jpg");
  background-size: cover; /* Ajusta la imagen para cubrir todo el div */
  background-size: 8.9cm 11.76cm;

  display: flex; /* Utilizado aquí solo para centrar el contenido, si es necesario */
  justify-content: center; /* Centra horizontalmente el contenido del div */
  align-items: center; /* Centra verticalmente el contenido del div */
  color: white; /* Color del texto, para asegurar que sea visible */
  border-radius: 12px;
}
.carnet::after {
  content: ''; /* Necesario para que el pseudo-elemento se genere */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(114, 114, 114, 0.4); /* Color de superposición negro con 50% de opacidad */
  border-radius: 12px;
  z-index: 1; /* Asegura que la superposición esté por encima del fondo pero debajo del contenido */
}

.header{
  display: flex;
  align-items: center;
  z-index: 10;
}
.header-logo img{
  width: 3.2rem;
  padding: 10px;
}
.header-title{

}
.header-title span{
  display: block;
  font-size: 1.5rem;
  color: #2b2b2b;
  font-weight: bold;
  font-family: Arial;
}
.body{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  z-index: 10;
}
.profile-carnet{
  display: contents;
  flex-direction: column;
}
.profile-carnet .colo-blue{
  background-color: #204e89;
  border-radius: 25px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3), /* Sombra exterior para profundidad */
             -5px -5px 10px rgba(255, 255, 255, 0.2); /* Sombra interior para brillo */
}
.profile-carnet .colo-ligthblue{
  background-color: rgb(97, 171, 204);
  border-radius: 25px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3), /* Sombra exterior para profundidad */
             -5px -5px 10px rgba(255, 255, 255, 0.2); /* Sombra interior para brillo */
}
.profile-carnet span{ 
  text-align: center;
  font-size: 1rem;
  color: rgb(230, 230, 230);
  font-weight: 700;
  font-family: Arial;
  padding: 5px 10px;
  margin: 5px 0;
}
.photo-carnet img{
  width: 100px;
  border: 1px solid #313131;
  background: #dfdfdf;
}
#qr{
  width: 100%;
  align-items: center;
}
.footer{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 2rem;
  z-index: 10;
}
.footer .qr-code{
  padding: 10px;
  background-color: white;

}
.footer .qr-code canvas{

}

</style>