
<!-- StartRoom.vue -->
<!-- Disable the "vue/no-unused-components" rule for this specific component -->
<!-- eslint-disable vue/no-unused-components -->
<template>
  <div class="startroom">
    <div class="startroom-content">
        <div class="siu-content">
            <a class="" @click="invokeMethodselectComponent('GuaraniComponent')">
                <img src="@/assets/icons/icons-starroom/siu-guarani.jpg" alt="Imagen" class="responsive-image"/>
            </a>
        </div>
        <!-- bloque el acceso desde el iframe 
        <div class="siu-content">
            <a class="no-drop" @click="invokeMethodselectComponent('CampusComponent')">
                <img src="@/assets/img/campus.png" alt="Imagen" class="responsive-image" />
            </a>
        </div>
        -->
        <div class="siu-content">
            <a class="" @click="invokeMethodselectComponent('TiendaComponent')">
                <img src="@/assets/icons/icons-starroom/tienda.jpg" alt="Imagen" class="responsive-image" />
            </a>
        </div>
        <div class="siu-content">
            <a class="" @click="invokeMethodselectComponent('CalendarioComponent')">
                <img src="@/assets/icons/icons-starroom/calendario.jpg" alt="Imagen" class="responsive-image"/>
            </a>
        </div>
        <!-- bloque el acceso desde el iframe 
        <div class="siu-content">
            <a class="no-drop" @click="invokeMethodselectComponent('CampusComponent')">
                <img src="@/assets/img/campus.png" alt="Imagen" class="responsive-image" />
            </a>
        </div>
        -->
        <div class="siu-content">
            <a class="" @click="invokeMethodselectComponent('BeneficiosComponent')">
                <img src="@/assets/icons/icons-starroom/beneficios2.jpg" alt="Imagen" class="responsive-image" />
            </a>
        </div>
    </div>
    <div class="carousel">
      <carousel :autoplay="5000"  :wrap-around="true" v-model="currentSlide">
        <Slide v-for="slide in slides" :key="slide.id">
          <div class="carousel__item">
            <a :href="slide.link">
              <img :src="slide.image" :alt="'Imagen ' + slide.name">
            </a>
          </div>
        </Slide>

        <template #addons>
          <navigation />
        </template>
      </carousel>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import GuaraniComponent from '@/components/SIU/GuaraniComponent.vue';
/* eslint-disable */
import CampusComponent from '@/components/SIU/CampusComponent.vue';
import TiendaComponent from '@/components/SIU/TiendaComponent.vue';
import CalendarioComponent from '@/components/SIU/CalendarioComponent.vue';
import BeneficiosComponent from '@/components/SIU/BeneficiosComponent.vue';


import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import LogoGuarani from '@/assets/img/Logo_guarani.png';
export default {
  props: ['parentMethods'],
  components: {
    GuaraniComponent,
    CampusComponent,
    TiendaComponent,
    CalendarioComponent,
    BeneficiosComponent,
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      slides: [
        { id: 1, name: "banner1", image: "https://www.unraf.edu.ar/images/BANNERS_INICIO_SLIDESHOW/banner1.jpeg", link: "https://logo.unraf.edu.ar/" },
        { id: 2, name: "1c", image: "https://www.unraf.edu.ar/images/BANNERS_INICIO_SLIDESHOW/1c.png", link: "https://www.unraf.edu.ar/index.php/menucontenidos/1245-noticia-490" },
        { id: 3, name: "nuevocampus1", image: "https://www.unraf.edu.ar/images/BANNERS_INICIO_SLIDESHOW/nuevocampus1.jpg", link: "https://www.unraf.edu.ar/index.php/menucontenidos/855-noticia-376" },
        // Agrega más objetos para más imágenes
      ],
    };
  },
  methods: {
    invokeMethodselectComponent(componentName) {
      this.parentMethods.selectComponent(componentName);
      // Llama a otros métodos del padre según sea necesario
    },
  },
};
</script>
<style scoped>
@import '@/assets/css/home.css';
.startroom{
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}
.startroom-content{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.siu-content {
  flex: 0 1 45%; /* Each item takes up to 45% of the line, adjusted for better spacing */
  box-sizing: border-box;
  padding: 10px; /* Provides space between items */
  display: flex; /* Makes siu-content a flex container */
  justify-content: center; /* Centers the content horizontally within each siu-content */
  align-items: center; /* Centers the content vertically within each siu-content */
}
.siu-content img{
  border-radius: 20px;
  box-shadow: -3px 3px rgba(128, 128, 128, 0.548);
}
.carousel{
  width: 100%;
  margin: 1vw 0;
  background-color: rgba(128, 128, 128, 0.548);
}
.carousel__item img{
  width: 100%;
  height: 16vw;
}
/* Media Query para tabletas (grande) */
@media (max-width: 768px) {
  .siu-content {

  }
  .carousel__item img{
  width: 100%;
  height: auto;
}
}
</style>