
<template>
  <div id="app">
    <header>
      <!-- Contenido del encabezado si es necesario -->
    </header>

    <main>
      <!-- Use the CustomAlert component -->
      <custom-alert
        v-if="$root.showNotification"
        :title="notificationDetails.title"
        :message="notificationDetails.body"
        @closeAlert="$root.showNotification = false"
      />
      <div v-if="!isAuthenticated" class="center-content flex-column">
        <GoogleLogin :callback="onSuccess" @error="onError" />
        <button class="google-signin-button google-signin-button-black" @click="toggleGuestMode">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eyeglasses" viewBox="0 0 16 16">
            <path d="M4 6a2 2 0 1 1 0 4 2 2 0 0 1 0-4m2.625.547a3 3 0 0 0-5.584.953H.5a.5.5 0 0 0 0 1h.541A3 3 0 0 0 7 8a1 1 0 0 1 2 0 3 3 0 0 0 5.959.5h.541a.5.5 0 0 0 0-1h-.541a3 3 0 0 0-5.584-.953A2 2 0 0 0 8 6c-.532 0-1.016.208-1.375.547M14 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0"/>
          </svg> Modo de Invitado
        </button>
      </div>
      <div class="content">
        <!-- Show the HomePage component if logged in -->
        <router-view v-if="isAuthenticated && userExists || isGuestMode" :actualizarUsuario="actualizarUsuario" :isGuestMode="isGuestMode" :userData="userData" :logout="logout" :profile="profile" :isAuthenticated="isAuthenticated"></router-view>

      </div>
      <div v-if="isAuthenticated && !userExists && !isGuestMode" class="center-content">
        <!-- Mostrar formulario si el usuario no está autenticado y no existe en la base de datos -->
        <UserForm :logout="logout" :email="profile.email" :image="profile.picture" />
      </div>
    </main>
    <div v-if="isLoading" class="loading-overlay">
      <div class="loading-spinner"></div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { googleLogout, decodeCredential, GoogleLogin } from 'vue3-google-login';
import UserForm from '@/components/UserForm.vue';
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import CustomAlert from '@/components/CustomAlert.vue';

export default {
  components: {
    UserForm,
    CustomAlert,
    GoogleLogin
  },
  setup() {
    const isAuthenticated = ref(false);
    const router = useRouter();
    const isLoading = ref(false);
    const userExists = ref(false);
    const profile = ref(null);
    const userData = ref(null);
    const showNotification = ref(false);
    const notificationDetails = ref({ title: '', body: '' });
    const isGuestMode = ref(false);

    const toggleGuestMode = async () => {
      if (!isAuthenticated.value) {
        const defaultProfile = {
          email: 'prueba@unraf.edu.ar',
          picture: require('@/assets/img/MOLINO.png'),
        };

        if (!profile.value) {
          localStorage.setItem('profile', JSON.stringify(defaultProfile));
          profile.value = defaultProfile;
        }

        await checkUserExistence("prueba@unraf.edu.ar");
        isAuthenticated.value = true;
        isGuestMode.value = true;
        router.push({ name: 'HomeView' });
      } else {
        showNotification.value = true;
        notificationDetails.value = { title: 'error', body: 'No registration Guest. Request permission to generate one.' };
      }
    };

    const firebaseConfig = {
      apiKey: "AIzaSyDEfVra1fT-oIMIE0lxXCTHHjoW9J0N2xo",
      authDomain: "pwa-app-unraf.firebaseapp.com",
      projectId: "pwa-app-unraf",
      storageBucket: "pwa-app-unraf.appspot.com",
      messagingSenderId: "954633072479",
      appId: "1:954633072479:web:6431cbbaec69d079f0d0ea",
      measurementId: "G-8PC464D2BM"
    };
    // eslint-disable-next-line no-unused-vars
    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging();

    const onMessageCallback = (payload) => {
      const { title, body } = payload.notification;
      notificationDetails.value = { title, body };
      showNotification.value = true;
    };

    onMessage(messaging, onMessageCallback);

    getToken(messaging, { vapidKey: 'BAfG_yxZolQBu6qwpQ0WmotA4Ckw-T6-pFbkGnDsuIyB7gRhADq5BQmJz7sV7_RHJwao_NiYub6p7QOZXT1j9-8' })
      .then((currentToken) => {
        if (currentToken) {
          console.log("Token is:", currentToken);
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });

    const checkUserExistence = async (email) => {
      try {
        const response = await fetch(`/api/buscar_usuario/${email}/`);
        if (response.ok) {
          const data = await response.json();
          userExists.value = data.existe;
          userData.value = data.datos;
        } else {
          showNotification.value = true;
          notificationDetails.value = { title: 'error', body: 'Error al obtener datos del usuario' };
          console.log('Error al obtener datos del usuario:', response.statusText);
        }
      } catch (error) {
        showNotification.value = true;
        notificationDetails.value = { title: 'error', body: 'Error al realizar la solicitud' };
        console.log('Error al realizar la solicitud:', error);
      }
    };

    const onSuccess = async (GoogleUser) => {
      const token = GoogleUser.credential;
      localStorage.setItem('access_token', token);
      try {
        const userData = decodeCredential(GoogleUser.credential);
        localStorage.setItem('profile', JSON.stringify(userData));
        profile.value = userData;
        await checkUserExistence(profile.value.email);
        isAuthenticated.value = true;
        router.push({ name: 'HomeView' });
      } catch (error) {
        showNotification.value = true;
        notificationDetails.value = { title: 'error', body: 'Error al procesar el perfil del usuario' };
        console.log('Error al procesar el perfil del usuario:', error);
      }
    };

    const onError = (error) => {
      showNotification.value = true;
      notificationDetails.value = { title: 'error', body: 'Error al iniciar sesión' };
      console.log('Error al iniciar sesión:', error);
    };

    const logout = () => {
      isAuthenticated.value = false;
      profile.value = null;
      showNotification.value = false;
      userExists.value = false;
      localStorage.removeItem('access_token');
      localStorage.removeItem('profile');
      googleLogout();
      router.push({ name: '' });
    };

    function toggleNavbar() {
      const navbarButtons = document.querySelector('.navbar-buttons');
      navbarButtons.classList.toggle('show');
    }

    function goToHome() {
      console.log('Ir a la página de inicio');
    }

    onMounted(async () => {
      try {
        const storedToken = localStorage.getItem('access_token');
        const storedProfile = localStorage.getItem('profile');
        if (storedToken && storedProfile) {
          profile.value = JSON.parse(storedProfile);
          await checkUserExistence(profile.value.email);
          isAuthenticated.value = true;
          router.push({ name: 'HomeView' });
        } else {
          console.log('Profile or email not available.');
        }
      } catch (error) {
        showNotification.value = true;
        notificationDetails.value = { title: 'error', body: 'Error al solicitar el permiso de notificación' };
        console.log('Error al solicitar el permiso de notificación:', error);
      }
    });

    return {
      isLoading,
      isAuthenticated,
      onSuccess,
      onError,
      logout,
      toggleNavbar,
      goToHome,
      userExists,
      profile,
      userData,
      showNotification,
      notificationDetails,
      isGuestMode,
      toggleGuestMode,
    };
  },
};
</script>

<style scoped>
@import 'assets/css/index.css';
</style>