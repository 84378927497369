<template>
  <div class="home">
    <div class="coin-container" style="display: none;">
      <div class="coin">
        <div class="coin-overlay"><div class="score">1</div></div>
        <div class="icon"></div>
      </div>
    </div>
    <div class="menu" ref="menuElement">
      <button @click="toggleMenu" class="dropbtn circle-container circle">
            <img src="@/assets/img/MOLINO.png" alt="Imagen" class="responsive-image" />
      </button>
    
      <div v-if="isOpen" class="menu-content">
        <!-- Contenido del dropdown -->
        <a @click="selectComponent('StartRoom')">
          <img class="icon-menu" src="@/assets/icons/icons-menu/home.png" alt="icon menu">
        </a>
        <a @click="selectComponent('QRScannerComponent')">
          <img class="icon-menu" src="@/assets/icons/icons-menu/qr.png" alt="icon menu">
        </a>
        <a v-if="userData && (userData.cargo==='NO DOCENTE' || userData.cargo==='DOCENTE' || userData.cargo==='ALUMNO' || userData.cargo==='SUPERIOR')" @click="selectComponent('CredentialQRComponent')">
          <img class="icon-menu" src="@/assets/icons/icons-menu/badge_black_24dp.svg" alt="icon menu">
        </a>
        <a  @click="selectComponent('PerfilComponent')">
          <img class="icon-menu" src="@/assets/icons/icons-menu/usuario.png" alt="icon menu">
        </a>
        <button class="logout-button" @click="logout" v-if="isAuthenticated">
          <img class="icon-menu" src="@/assets/icons/icons-menu/salida.png" alt="icon menu">
        </button>
      </div>
    </div>
    <div class="content vh100 ">
      <!-- Aquí se mostrará el componente seleccionado -->
      <component :email="email" :logout="logout" :isGuestMode= "isGuestMode" :image="profile.picture" :profile="userData" :is="selectedComponent" :parentMethods="parentMethods" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import StartRoom from '@/components/StartRoom.vue';
import GuaraniComponent from '@/components/SIU/GuaraniComponent.vue';
import CampusComponent from '@/components/SIU/CampusComponent.vue';
import TiendaComponent from '@/components/SIU/TiendaComponent.vue';
import QRScannerComponent from '@/components/QR/QRScannerComponent.vue';
import CredentialQRComponent from '@/components/QR/CredentialQRComponent.vue';
import PerfilComponent from '@/components/Perfil/PerfilComponent.vue'
import CalendarioComponent from '@/components/SIU/CalendarioComponent.vue';
import BeneficiosComponent from '@/components/SIU/BeneficiosComponent.vue';
// eslint-disable-next-line
import { ref } from 'vue';
// eslint-disable-next-line
import { QrcodeStream } from 'vue-qrcode-reader'
// eslint-disable-next-line
const componentesPermitidos = ['GuaraniComponent', 'TiendaComponent', 'CampusComponent', 'BeneficiosComponent', 'CalendarioComponent'];
export default {
  props: [
    'isAuthenticated',
    'profile',
    'logout',
    'isGuestMode',
    'userData',
    'actualizarUsuario',
  ],
  data() {
    return {
      isOpen: false,
      selectedComponent: 'StartRoom',
      isSIU: true,
      email: this.profile.email,
    };
  },
  components: {
    StartRoom,
    GuaraniComponent,
    CampusComponent,
    TiendaComponent,
    QRScannerComponent,
    CredentialQRComponent,
    PerfilComponent,
    CalendarioComponent,
    BeneficiosComponent

  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
    selectComponent(componentName) {
      this.selectedComponent = componentName;
      if (componentesPermitidos.includes(this.selectedComponent)) {
        this.isSIU = true;
      }else{
        this.isSIU = false;
      }
      //this.cambiarEstilo();
      this.isOpen = false; // Cierra el menú al seleccionar un componente
    },
    cambiarEstilo() {
      const menuElement = this.$refs.menuElement;
      // Cambiar el estilo según la lógica que desees
      if (this.isSIU) {
        menuElement.style.left = 'auto';
        menuElement.style.right = '32px';
      } else {
        menuElement.style.left = '32px';
        menuElement.style.right = 'auto'; // Establecer el valor por defecto cuando isSIU no es verdadero
      }
    },
  },
  computed: {
    parentMethods() {
      return {
        selectComponent: this.selectComponent,
        logout: this.logout,
        actualizarUsuario: this.actualizarUsuario
        // ... other methods ...
      };
    },
  },
};
</script>
<style scoped>
@import '@/assets/css/home.css';
@import '@/assets/css/coin.css';
</style>