<!-- TiendaComponent -->
<template>
    <div class="iframe-container">
      <iframe
        ref="iframe"
        src="https://tutienda.unraf.edu.ar"
        frameborder="0"
        allowfullscreen
      ></iframe>
    </div>
  </template>
  <script>
  export default {
    mounted() {
      window.addEventListener('resize', this.resizeIframe);
      this.resizeIframe(); // Llama a la función cuando se carga el componente
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.resizeIframe);
    },
    methods: {
      resizeIframe() {
        const iframe = this.$refs.iframe;
        if (iframe) {
          // Establece la altura del iframe como un porcentaje del alto de la ventana
          iframe.style.height = `${window.innerHeight * 1}px`;
        }
      },
    },
  };
  </script>
  
  <style scoped>
    @import '@/assets/css/index.css';
  </style>