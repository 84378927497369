<!-- CampusComponent -->
<template>
    <div class="iframe-container">
      <iframe class="iframe-calendar"
        ref="iframe"
        src="https://www.unraf.edu.ar/pwa-calendario"
        sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
        frameborder="0"
        allowfullscreen
      ></iframe>
    </div>
  </template>
  <script>
  export default {
    mounted() {
      window.addEventListener('resize', this.resizeIframe);
      this.resizeIframe(); // Llama a la función cuando se carga el componente
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.resizeIframe);
    },
    methods: {
      resizeIframe() {
        const iframe = this.$refs.iframe;
        if (iframe) {
          // Establece la altura del iframe como un porcentaje del alto de la ventana
          iframe.style.height = `${window.innerHeight * 1}px`;
        }
      },
    },
  };
  </script>
  
  <style scoped>
  @import '@/assets/css/index.css';
  #sp-main-body{
    padding: 0;
  }
  .iframe-container {
    position: relative;
    width: 100%;
    height: 600px;
    overflow: hidden;
    padding-top: 0; /* Proporción 16:9 para mantener la relación de aspecto */
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 600px;
    overflow: auto;
  }
  </style>