// router/index.js
import { createRouter, createWebHistory } from 'vue-router';
// eslint-disable-next-line
import HomeView from '../views/HomeView.vue';
// eslint-disable-next-line
import LoginView from '../views/LoginView.vue';

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue'),  // Importar de manera diferida
    props: true
  },
  {
    path: '/login',
    name: 'LoginView',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue'),  // Importar de manera diferida
    
  },
  // Otras rutas...
];

const router = createRouter({
  history: createWebHistory('/unraf'),
  routes,
});

export default router;