<template>
  <div>
    <GoogleSignInButton @success="handleGoogleLoginSuccess" @error="handleGoogleLoginError" />
  </div>
</template>

<script setup>
import { inject } from 'vue';

const isAuthenticated = inject('isAuthenticated');

const handleGoogleLoginSuccess = (response) => {
  const credential = response.credential;
  console.log("Google Access Token:", credential);

    // Guardar credenciales en localStorage
    localStorage.setItem('googleAccessToken', credential);

    // Establecer isAuthenticated en true
    isAuthenticated.value = true;
};

const handleGoogleLoginError = () => {
  console.error('Google login failed');
  // Manejar el error de inicio de sesión de Google de manera adecuada (mostrar mensaje al usuario, etc.)
};
</script>
