<!-- CustomAlert.vue -->

<template>
    <div v-if="isVisible" :class="title" class="custom-alert">
      <!-- section -->
      <div class="wrapper" >
        <div class="card" :class="title">
          <div class="icon-alert">
            <i class="fas fa-check-circle"></i></div>
            <div class="subject">
                <h3>{{ capitalizeFirstLetter(title) }}</h3>
                <p>{{ message }}</p>
            </div>
            <div class="icon-times">
                <button @click="closeAlert">Close</button>
            </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, getCurrentInstance } from 'vue';
  
  export default {
    props: {
      title: String,
      message: String,
    },
    methods: {
      capitalizeFirstLetter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
      },
    },
    // eslint-disable-next-line
    setup(props) {
      const isVisible = ref(true);
      const { emit } = getCurrentInstance();
  
      const closeAlert = () => {
        isVisible.value = false;
        emit('closeAlert'); // Emitir el evento closeAlert
      };
  
      return {
        isVisible,
        closeAlert,
      };
    },
  };
  </script>
  
  <style scoped>
  .custom-alert {
    position: fixed;
    width: 100%;
    z-index: 1000;
  }
      /* success */
      .wrapper {
        padding: 10px;
      }
      .wrapper .card.success  {
        background-color: #fff;
        padding: 10px 20px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-left: 5px solid #49d761;
        border-radius: 3px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      }
      .wrapper .card.error  {
        background-color: #fff;
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-left: 5px solid #fd3a20;
        border-radius: 3px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      }
      .wrapper .card.info  {
        background-color: #fff;
        padding: 10px 20px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-left: 5px solid #2078fd;
        border-radius: 3px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      }
      .wrapper .card.warning  {
        background-color: #fff;
        padding: 10px 20px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-left: 5px solid #fdd420;
        border-radius: 3px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      }
      .wrapper .card .subject {
        width: 80%;
      }
      .success{
       color: #49d761;
      }
      .error{
       color: #fd3a20;
      }
      .info{
       color: #2078fd;
      }
      .warning{
       color: #fdd420;
      }
      .wrapper .card .subject p {
        color: #909092;
      }
      .wrapper .card .icon-alert {
        font-size: 28px;
      }
      .wrapper-success .card .icon-times {
        font-size: 28px;
        color: #c3c2c7;
        cursor: pointer;
      }
      /* success */
  button {
    margin-top: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: #8b8b8b;
    color: #d8d8d8;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
  }
  </style>