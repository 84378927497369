import { createStore } from 'vuex';

export default createStore({
  state: {
    userProfile: null,
  },
  mutations: {
    setProfile(state, profile) {
      state.userProfile = profile;
    },
    clearProfile(state) {
      state.userProfile = null;
    },
  },
  actions: {
    setProfile({ commit }, profile) {
      commit('setProfile', profile);
    },
    clearProfile({ commit }) {
      commit('clearProfile');
    },
  },
  getters: {
    userProfile: (state) => state.userProfile,
  },
});